<template>
  <module
      ref="module"
      id="brokerPerformance"
      title="Broker Performance"
      titleIcon="fa fa-usd"
      :filters.sync="filters"
      :visibilityFields.sync="tableColumns"
      :showVisibilityButton="true"
      apiUrl="MAINAPIURLV4"
      :url="`/${broker}/performance/internal`"
      :useExports="true"
      :exportHeader="headers"
  >
    <template slot="toolbar-global">
      <div class="mr-1" v-if="isAdmin">
        <select class="form-control"
                v-model="brokerFiltered"
                @change="filterByBroker">
          <option selected disabled value="undefined">Select Broker</option>
          <option v-for="broker in brokers" :value="broker">{{ broker }}</option>
          <option value="FBS">Your Broker</option>
        </select>
      </div>
      <p-button type="success" v-on:click="openModal">
        <i class="fa fa-credit-card"></i>
      </p-button>
    </template>
    <template slot="global">
      <broker-performance-modal
          :entries="selectedRow"
          @on-close-modal-broker-performance="closeModalBrokerPerformance"
          :show-modal-confirm-performance="modalConfirmPerformance">
      </broker-performance-modal>
    </template>
    <template slot="table-columns">

      <el-table-column type="selection" disabled>
        <!-- <template slot-scope="row" v-if="!row.date_close"/> -->
      </el-table-column>


      <el-table-column width="50px" label="#" sortable>
        <template slot-scope="{row}">
          <i v-if="row.date_close" class="fa fa-lock"></i>
          <i v-else class="fa fa-clock-o"></i>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.date_reference.prop"
          v-if="tableColumns.date_reference.isVisible"
          :label="tableColumns.date_reference.label"
          :minWidth="tableColumns.date_reference.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.date_reference }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.account.prop"
          v-if="tableColumns.account.isVisible"
          :label="tableColumns.account.label"
          align="center"
          :minWidth="tableColumns.account.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.account }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.strategy.prop"
          v-if="tableColumns.strategy.isVisible"
          :label="tableColumns.strategy.label"
          align="center"
          :minWidth="tableColumns.strategy.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-primary'">
            {{ row.strategy }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.currency.prop"
          v-if="tableColumns.currency.isVisible"
          :label="tableColumns.currency.label"
          minWidth="100px"
          align="center"
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.currency }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.total_profit.prop"
          v-if="tableColumns.total_profit.isVisible"
          :label="tableColumns.total_profit.label"
          align="left"
          :minWidth="tableColumns.total_profit.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder text-success'">
            {{ getCurrencySymbol(row.currency) }} {{ row.total_profit }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.watermark.prop"
          v-if="tableColumns.watermark.isVisible"
          :label="tableColumns.watermark.label"
          align="left"
          :minWidth="tableColumns.watermark.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder text-success'">
            {{ getCurrencySymbol(row.currency) }} {{ row.watermark }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.billable_profit.prop"
          v-if="tableColumns.billable_profit.isVisible"
          :label="tableColumns.billable_profit.label"
          align="left"
          :minWidth="tableColumns.billable_profit.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder text-success'">
            {{ getCurrencySymbol(row.currency) }} {{ row.billable_profit }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.performance_fee.prop"
          v-if="tableColumns.performance_fee.isVisible"
          :label="tableColumns.performance_fee.label"
          :minWidth="tableColumns.performance_fee.width"
          align="left"
          sortable>
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder text-success'">
            {{ getCurrencySymbol(row.currency) }}
            {{ row.performance_fee }}
          </b>
          <small>
            ({{ parseFloat(row.performance_fee_per * 100).toFixed(0) }}%)
          </small>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.master_account.prop"
          v-if="tableColumns.master_account.isVisible"
          :label="tableColumns.master_account.label"
          align="left"
          :minWidth="tableColumns.master_account.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.master_account }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.billing_type.prop"
          v-if="tableColumns.billing_type.isVisible"
          :label="tableColumns.billing_type.label"
          :minWidth="tableColumns.billing_type.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.billing_type }}
          </b>
        </template>
      </el-table-column>


      <el-table-column
          :prop="tableColumns.date_close.prop"
          v-if="tableColumns.date_close.isVisible"
          :label="tableColumns.date_close.label"
          :minWidth="tableColumns.date_close.width"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.date_close }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.description.prop"
          v-if="tableColumns.description.isVisible"
          :label="tableColumns.description.label"
          minWidth="200px"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ row.description }}
          </b>
        </template>
      </el-table-column>

      <el-table-column
          :prop="tableColumns.status.prop"
          v-if="tableColumns.status.isVisible"
          :label="tableColumns.status.label"
          minWidth="200px"
          sortable
      >
        <template slot-scope="{row}">
          <b :class="row.date_close ? 'text-gray-dark' : 'text-bolder'">
            {{ getStatusValue(row.status) }}
          </b>
        </template>
      </el-table-column>

    </template>
    <template slot="filter-field">
      <div class="form-group">
        <label>{{ $t('status') }}</label>
        <el-select v-model="filters.status" clearable>
          <el-option value="= 0" :label="$t('noDebitorCredit')"></el-option>
          <el-option value="= 1" :label="$t('onlyDebitedFollower')"></el-option>
          <el-option value="= 2" :label="$t('debitAndCreditSuccessful')"></el-option>
          <el-option value="is null" :label="$t('pendingtxt')"></el-option>
          <el-option value="" :label="$t('alltxt')"></el-option>
        </el-select>
      </div>
      <fg-input v-model="filters.account" :label="$t('account')"></fg-input>
      <fg-input v-model="filters.strategy" :label="$t('strategytxt')"></fg-input>
      <fg-input :label="$t('date_reference')">
        <date-picker
            id="rebrand-datapick"
            v-model="filters.date_reference">
        </date-picker>
      </fg-input>

      <fg-input :label="$t('date_close')">
        <date-picker
            id="rebrand-datapick"
            v-model="filters.date_close">
        </date-picker>
      </fg-input>


    </template>
    <template slot="visibility-field"></template>
  </module>
</template>

<script>
import Vue from "vue";
import {Option, Select, Table, TableColumn, Tooltip} from "element-ui";
import DatePicker from "@/components/UIComponents/Inputs/DatePicker";
import Constants from "../../../../../assets/constants";
import {Badge, Modal} from "src/components/UIComponents";
import BrokerPerformanceModal from "./BrokerPerformanceModal.vue";
import {getCurrency} from "../../../../../util/CurrencyUtils";


const rules = Constants.methods.getRules();
const visibility = localStorage.getItem('visibility_items');
const json = visibility ? JSON.parse(visibility) : null;


Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  name: "BrokerPerformance",
  components: {
    BrokerPerformanceModal,
    Modal,
    Badge,
    DatePicker,
  },
  computed: {
    broker() {
      if (rules.isAdmin) {
        return this.brokerFiltered || 'a';
      }
      return rules.broker || 'a';
    },
    isAdmin() {
      return rules.isAdmin;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    let self = this;
    return {
      inprogress: true,
      searchQuery: '',
      propsToSearch: ['userid'],
      filters: {},
      brokerFiltered: undefined,
      brokers: [],
      headers: [
        "account",
        "billable_profit",
        "billing_type",
        "broker",
        "currency",
        "date_close",
        "date_generated",
        "date_reference",
        "description",
        "id",
        "master_account",
        "master_broker",
        "performance_fee",
        "performance_fee_per",
        "reference",
        "status",
        "strategy",
        "total_profit",
        "watermark"
      ],
      tableColumns: json || {
        status: {
          prop: 'status',
          label: self.$t('status'),
          isVisible: true,
          width: '130px',
        },
        account: {
          prop: 'account',
          label: self.$t('account'),
          isVisible: true,
          width: '100px',
        },
        strategy: {
          prop: 'strategy',
          label: self.$t('strategytxt'),
          isVisible: true,
          width: '200px',
        },
        currency: {
          prop: 'currency',
          label: self.$t('currency'),
          isVisible: true,
        },
        total_profit: {
          prop: 'total_profit',
          label: self.$t('totalprofit'),
          isVisible: false,
          width: '150px',
        },
        watermark: {
          prop: 'watermark',
          label: self.$t('watermark'),
          isVisible: false,
          width: '150px',
        },
        billable_profit: {
          prop: 'billable_profit',
          label: self.$t('billable_profit'),
          isVisible: true,
          width: '150px',
        },
        performance_fee: {
          prop: 'performance_fee',
          label: self.$t('performance_fee'),
          isVisible: true,
          width: '150px',
        },
        master_account: {
          prop: 'master_account',
          label: self.$t('master'),
          isVisible: true,
          width: '150px',
        },
        billing_type: {
          prop: 'billing_type',
          label: self.$t('billing_type'),
          isVisible: false,
          width: '150px',
        },
        date_reference: {
          prop: 'date_reference',
          label: self.$t('date_reference'),
          isVisible: true,
          width: '150px',
        },
        date_close: {
          prop: 'date_close',
          label: self.$t('date_close'),
          isVisible: false,
          width: '150px',
        },
        description: {
          prop: 'description',
          label: self.$t('description'),
          isVisible: false,
          width: '150px',
        },
      },
      selectedRow: [],
      allRows: [],
      tableData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      modalConfirmPerformance: false,
      pickerOptions1: {
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'A week ago',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
      },
      datePicker: '',
    }
  },
  methods: {
    openModal() {
      if (this.$refs.module.checkedRows.length === 0) {
        return Vue.$toast.warning(this.$t('mustSelectAccount'));
      } else {
        this.allRows = this.$refs.module.checkedRows
        // console.log(this.allRows)
        this.selectedRow = this.$refs.module.checkedRows
        for (let i = 0; i < this.selectedRow.length; i++) {
          if (this.selectedRow[i].date_close) {
            this.selectedRow = []
            return Vue.$toast.warning(this.$t('cantSelectClosed'));
          }
        }
        this.modalConfirmPerformance = true
      }
    },
    closeModalBrokerPerformance() {
      this.modalConfirmPerformance = false
      this.selectedRow = []
      setTimeout(this.$refs.module.refreshList(), 4500);
    },
    async fillCheck() {
      return this.$refs.module.checkedRows.length === 0 ? [] : this.$refs.module.checkedRows
    },
    getCurrencySymbol(code) {
      return getCurrency(code);
    },
    getPillClass(type) {
      if (type === 0) {
        return 'danger'
      } else if (type === 1) {
        return 'success'
      } else if (type === 2) {
        return 'info'
      } else {
        return 'default'
      }
    },
    getStatusValue(type) {
      if (type === 0) {
        return this.$t('noDebitorCredit')
      } else if (type === 1) {
        return this.$t('onlyDebitedFollower')
      } else if (type === 2) {
        return this.$t('debitAndCreditSuccessful')
      } else {
        return this.$t('pendingtxt')
      }
    },
    filterByBroker() {
      this.$refs.module.tableLoading = true;
      this.$refs.module.search();
    },
  },
  beforeMount() {
    const self = this;
    this.$listBrokersInternalBilling()
        .then(resp => {
          self.brokers = resp.data.map(broker => {
            return broker.name
          });
        });
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
#brokerPerformance::v-deep {
  .cell {
    white-space: nowrap;
  }
}

#rebrand-datapick::v-deep {
  width: 100%;
}
</style>
